import React from 'react'
import { css } from '@emotion/core'
import { Col, Box } from 'boostly-ui2'
import Copyright from '../Copyright'
import FontLoader from '../LoadFont'
 
const Layout = ({ bgShape, children }) => (
  <Col
    minHeight={`100vh`}
    p={3}
    css={css`
      background-image: url("${bgShape}");
      background-repeat: no-repeat;
      background-position: center top;
      background-color: #f6fcff;
      width: 100;
    `}
  >
    <FontLoader />
    <Box maxWidth={`450px`} m={`0 auto`} pt={`24px`} px={`16px`} height={`100%`}>
      {children}
    </Box>
    <Box textAlign={`center`} my={3} alignSelf={`center`}>
      <Copyright />
    </Box>
  </Col>
)

export default Layout
