/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'

import { Box, Row, Badge, settings } from "boostly-ui2"
import Logo from './Logo'

const Box2 = styled(Box)`
  text-decoration: none;
`

const Copyright = () => (
    <Badge bg={`rgba(0, 0, 0, 0.05)`}>
      <Row
        is={`a`}
        x
        y
        href={`https://www.boostly.com`}
        target={`_blank`}
        css={css`text-decoration: none;`}
      >
        <span role={`img`} aria-label={`fire`} style={{ paddingRight: "2.5px", textDecoration: "none" }}>
          🔥
        </span>
        <Box2 is="span" color={settings.colors.midnight}>
          by
        </Box2>
        <Box px={1} pt={`4px`}>
          <Logo />
        </Box>
      </Row>
    </Badge>
	)
	
  export default Copyright